<template>
  <div>
    <CRow>
      <CCol md="8">
        <CCard>
          <CCardBody class="p-4">
            <CButton
              color="success"
              type="button"
              to="/playlists/new"
              class="float-right"
            >
              Add new playlist
            </CButton>

            <h1>My Playlists</h1>

            <div
              class="py-3 border-bottom mt-1"
              v-for="playlist in playlists"
              :key="playlist.id"
            >
              <CLink
                class="d-inline-block"
                :to="{ name: 'Show Playlist', params: { id: playlist.id } }"
              >
                <h4>{{ playlist.name }}</h4>
              </CLink>
              <div class="clearfix"></div>
              <div class="float-right text-muted">
                {{ $d(new Date(playlist.created_at), "short") }}
              </div>
              <CBadge color="success" class="mr-2">{{
                playlist.status
              }}</CBadge>
              <span
                >{{ playlist.totalChannels }} channels from
                {{ playlist.provider.name }}</span
              >
            </div>
          </CCardBody>
          <!-- <CCardFooter> Standard Footer. </CCardFooter> -->
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
// import { mapState, mapGetters } from "vuex";
import playlists from "@/api/playlists";
// import providers from "@/api/providers";

export default {
  data() {
    return {
      playlists: [],
      providers: [],
    };
  },
  //   computed: mapState({
  //     user: (state) => state.auth.user,
  //     ...mapGetters({
  //       authStatus: "auth/authStatus",
  //       isLoggedIn: "auth/isLoggedIn",
  //     }),
  //   }),
  watch: {
    $route() {
      this.loadPlaylists();
    },
  },
  mounted() {
    this.loadPlaylists();
  },
  methods: {
    loadPlaylists() {
      playlists
        .getPlaylists()
        .then((data) => {
          this.playlists = data.data;
        })
        .catch(() => {});
    },
  },
};
</script>